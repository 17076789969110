import {getLocalizations} from "~/apollo/queries/localizations";
import type {Localization} from "~/gql/schema";

export const useSupportedCountries = async (): Promise<string[]> => {
    const {data} = await useAsyncQuery<{localization: Localization}>(
        getLocalizations,
    )
    if (!data.value) {
        return ['US']
    }
    return data.value.localization.availableCountries.map((country: any) => country.isoCode)
}